<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
       <div class="row justify-content-center p-0 m-0">
        <div class="col-12 col-lg-8 p-0 m-0">
          <div  class="px-3 pb-5">
          <h2 class="doc-title"> gold X Standard Terms and Conditions: </h2>
            <p class="doc-pera">
              The gold X token is the free reward token of the fuse.gold platform, by simply registering and if eligible criteria and KYC are met and you will receive the gold X token on the launch of the platform. </p>
            <p class="doc-pera">
              During the registration phase of the launch each individual participant is awarded gold X points which equate to a percentage share of the overall token reward available, the initial free 500,000.00 gold X tokens may be  increased if a certain threshold of sign-ups is surpassed. Percentage points can be increased by utilising the gold X and fuse.gold referral incentive, please see the reward referral scheme. Alternatively, your percentage share could decrease without referrals.</p>
            <h2 class="doc-title">Eligible Criteria:</h2>
              
            <!-- </p> -->
            <ol class="pl-4">
              <li>
                <p class="doc-pera">
                  All participants in the reservation of the reward token of gold X must be at least 18 years of age to access and use the site and platform upon launch. 
                </p>
              </li>
              <li>
                <p class="doc-pera">All eligible participants can only receive the reward once.</p>
              </li>
              <li>
                <p class="doc-pera">
                  All participants must validate their email address prior to launch date, to be awarded gold X percentage points prior to conversion to the gold X token.
                </p>
              </li>
              <li>
                <p class="doc-pera">
                  Each participant that meets the eligible criteria will be rewarded with the reward token based on the gold X percentage points converted prior to the platform launch date.</p>
              </li>
              <li>
                <p class="doc-pera">
                  On the fuse.gold platform launch you must register with the Company to use the Site and the Platform; you agree to provide complete and accurate information when registering to use the Site and the Platform, and to keep that information updated.</p>
              </li>
              <li>
                <p class="doc-pera">
                  In addition to any other eligibility criteria set forth in these gold X and fuse.gold rules, all participants who wish to activate the gold X reward token must open an approved, KYC-verified, fuse.gold account on or after launch date.</p>
              </li>
              <li>
                <p class="doc-pera">
                  Conversion of the gold X percentage points to the gold X reward token must be completed within 3 months of the live launch date, failure to complete the opening of a KYC-verified, fuse.gold account within the 3-month period will result in the expiry of the rewards token and points. Any tokens unclaimed or to be found to ineligible will rewarded to the qualified customers according to their total share size of the gold X reward pool</p>
              </li>
              </ol>
              <!-- <p class="doc-pera">Referral Scheme</p> -->
            <h2 class="doc-title">Referral Scheme:</h2>

              <ol class="pl-4">
              <li>
                <p class="doc-pera">On registration of the free reward token each participant will be given a unique referral link.
</p>
              </li>
              <li>
                <p class="doc-pera">
                  The unique referral link can be used to register other eligible participants to the increase your individual gold X percentage points prior to launch. The referred person must meet the eligible criteria.</p>
              </li>
              </ol>
              <!-- <p class="doc-pera">Redeemable:</p> -->
            <h2 class="doc-title">Redeemable:</h2>

              <ol class="pl-4">
              <li>
                <p class="doc-pera">
 
                  On the fuse.gold platform launch and the conversion to the gold X reward token, fuse.gold will only permit to gold X tokens to be redeemed in specific tranches. These redeemable tranches will be activated 6 months after the fuse.gold platform launch and redeemable for a 2-week period and occur every 3 months after.</p>
              </li>
              <li>
                <p class="doc-pera">During the blackout periods between tranches gold X is not redeemable.</p>
              </li>
              <li><p class="doc-pera">
                gold X tokens can be redeemed for fuse.gold via the platform, a further redemption to cash can be utilised via the fuse.gold platform</p></li>
              <li><p class="doc-pera">Each and every customer will need to have a MetaMask wallet to be able to claim their free rewards. Other wallets will be supported further into the road map of the fuse.gold platform</p></li>
              <li><p class="doc-pera">gold X and fuse.gold reserves the right to amend the redeemable tranches.
</p></li></ol>
<!-- <p class="doc-pera">General Terms:</p> -->
<h2 class="doc-title">General Terms:</h2>

<ol class="pl-4">
              <li><p class="doc-pera">
                gold X and fuse.gold reserves the right to cancel or amend these rules and the specifications, terms and conditions at its sole discretion.</p></li>
              <li><p class="doc-pera">
                By registering and on platform launch of accepting the Token, you represent that you have read, understood and agreed to be bound by terms and conditions and you acknowledge and agree to be legally bound without limitation or qualification. You further agree that all decisions of gold X and fuse.gold shall be final and conclusive.</p></li>
              <li><p class="doc-pera">Users from the following sanctioned countries are excluded:</p>
              <ul class="pl-2">
                <li><p class="doc-pera">Iran</p></li>
                <li><p class="doc-pera">Libya</p></li>
                <li><p class="doc-pera">North Korea</p></li>
                <li><p class="doc-pera">Somalia</p></li>
                <li><p class="doc-pera">South Sudan</p></li>
                <li><p class="doc-pera">Sudan</p></li>
                <li><p class="doc-pera">Syria</p></li>
              </ul>
              </li>
              <li><p class="doc-pera">
                gold X and fuse.gold at its sole discretion will determine the eligibility criteria for each user including the amount of Tokens to be distributed to Eligible Users that satisfy specified criteria. Different Eligible Users may receive different amounts of Tokens depending on the criteria determined by gold X and fuse.gold. 
              </p></li>
              <li><p class="doc-pera">
                gold X and fuse.gold reserves the sole and absolute right to disqualify any participant that gold X and fuse.gold deems ineligible for the gold X token. 
              </p></li>
              <li><p class="doc-pera">
                The goldX Token are not transferable, and no substitutions.
              </p></li>
              <li><p class="doc-pera">
                gold X and fuse.gold decisions in any matter in relation to the reward are final and conclusive. gold X and fuse.gold will not entertain any requests for appeal or review.
              </p></li>
            </ol>

          </div>
        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   },
    name: "TermsConditions",
}
</script>

<style>

</style>